export const facetBucketMap: {
  [key: string]: {
    [key: string]: {min: number; max?: number; label: string; order: number};
  };
} = {
  duration: {
    '1': {
      min: 1,
      max: 6,
      label: '1 - 6 Nights',
      order: 1,
    },
    '2': {
      min: 7,
      max: 9,
      label: '7 - 9 Nights',
      order: 2,
    },
    '3': {
      min: 10,
      max: 13,
      label: '10 - 13 Nights',
      order: 3,
    },
    '4': {
      min: 14,
      max: 21,
      label: '14 - 21 Nights',
      order: 4,
    },
    '5': {
      min: 22,
      label: '22 + Nights',
      order: 5,
    },
  },
  min_price: {
    '1': {
      min: 1,
      max: 999,
      label: 'Less than $999',
      order: 1,
    },
    '2': {
      min: 1000,
      max: 1999,
      label: '$1000 - $1999',
      order: 2,
    },
    '3': {
      min: 2000,
      max: 2999,
      label: '$2000 - $2999',
      order: 3,
    },
    '4': {
      min: 3000,
      max: 3999,
      label: '$3000 - $3999',
      order: 4,
    },
    '5': {
      min: 4000,
      max: 4999,
      label: '$4000 - $4999',
      order: 5,
    },
    '6': {
      min: 5000,
      max: 5999,
      label: '$5000 - $5999',
      order: 6,
    },
    '7': {
      min: 6000,
      max: 6999,
      label: '$6000 - $6999',
      order: 7,
    },
    '8': {
      min: 7000,
      max: 7999,
      label: '$7000 - $7999',
      order: 8,
    },
    '9': {
      min: 8000,
      max: 8999,
      label: '$8000 - $8999',
      order: 9,
    },
    '10': {
      min: 9000,
      max: 9999,
      label: '$9000 - $9999',
      order: 10,
    },
    '11': {
      min: 10000,
      max: 19999,
      label: '$10000 - $19999',
      order: 11,
    },
    '12': {
      min: 20000,
      max: 29999,
      label: '$20000 - $29999',
      order: 12,
    },
    '13': {
      min: 30000,
      label: 'More than $29999',
      order: 13,
    },
  },
};

export const cruiseTypeLabelMap: {
  [key: string]: {label: string; order: number};
} = {
  CruiseOnly: {
    label: 'Cruises',
    order: 1,
  },
  CruiseTour: {
    label: 'CruiseTours',
    order: 2,
  },
};
